import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { isNil, equals, sort } from 'ramda';

import { getSubjectBundle } from 'store/plan/planSlice';

import { compareDate } from 'helpers/compareValue';

import Header from 'components/Header';
import { Button, PopupModal, TrialSelector, Spinner } from 'components/Shared';

import MikoExpiredImg from './miko-expired.png';

import { Container, Content, ButtonGroup } from './Expired.styles';

const Expired = () => {
  const { t } = useTranslation(['expired']);
  const dispatch = useDispatch();
  const [showPlans, setShowPlans] = useState(false);
  const { userID, timezone } = useSelector((state) => state.login);
  const { products, subjectBundles, isLoading } = useSelector(
    (state) => state.plan
  );

  const loading = subjectBundles.isLoading || isLoading;

  // mapping between products & price bundle to get the latest expired bundle id
  const expiredBundle = () => {
    const latestSubjects =
      products
        ?.map((item) => item.expiredSubscriptionProducts)
        ?.flat()
        ?.filter((prod) => !prod.isTrial)
        ?.reduce((acc, obj) => {
          const key = `${obj.endDate}-${obj.startDate}`;
          const { endDate, startDate } = obj;
          let group = acc.find((item) => item.key === key);
          if (!group) {
            group = { key, subjectIds: [], endDate, startDate };
            acc.push(group);
          }
          group.subjectIds.push(obj.subjectId);

          return acc;
        }, [])
        ?.sort(compareDate('startDate', 'desc'))
        ?.sort(compareDate('endDate', 'desc'))[0] ?? null;
    const subjectIdOrder = (a, b) => a - b;

    const latestBundleId = subjectBundles?.data?.find((item) =>
      equals(
        sort(subjectIdOrder, item.subjectIds),
        sort(subjectIdOrder, latestSubjects?.subjectIds)
      )
    );
    return { latestBundleId, latestSubjects } ?? null;
  };

  useEffect(() => {
    if (!isNil(userID)) {
      dispatch(getSubjectBundle(userID));
    }
  }, [dispatch, userID]);

  return (
    <div>
      <PopupModal show={showPlans} hide={() => setShowPlans(false)}>
        <TrialSelector
          onClose={() => setShowPlans(false)}
          userId={userID}
          campaignCode="ExpirePage"
        />
      </PopupModal>

      <Header toggleMenu={false} toggleAccessMenu={false} />
      <Container>
        <Content>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <div className="title">
                <p className="text">
                  {t(
                    'expired:title',
                    {
                      plan: `${expiredBundle()?.latestBundleId?.bundleName}`,
                    },
                    `Your Access to ${
                      expiredBundle()?.latestBundleId?.bundleName || ''
                    } has Expired`
                  )}
                </p>
                <p className="period">
                  {t(
                    'expired:period',
                    {
                      startDate: moment
                        .tz(
                          expiredBundle()?.latestSubjects?.startDate,
                          timezone
                        )
                        .format('DD MMMM, YYYY'),
                      endDate: moment
                        .tz(expiredBundle()?.latestSubjects?.endDate, timezone)
                        .subtract('1', 'day')
                        .format('DD MMMM, YYYY'),
                    },
                    `Active from ${moment
                      .tz(expiredBundle()?.latestSubjects?.startDate, timezone)
                      .format('DD MMM, YYYY')} to ${moment
                      .tz(expiredBundle()?.latestSubjects?.endDate, timezone)
                      .subtract('1', 'day')
                      .format('DD MMM, YYYY')}`
                  )}
                </p>
              </div>
              <div className="subtitle">
                <p>
                  {t(
                    'expired:subtitle',
                    'Subscribe now to continue all the fun challenges & missions, and claim all your KoKo Credits!'
                  )}
                </p>
                <p>
                  {t(
                    'expired:subtitle2',
                    'Get our best offer via the link below 👇'
                  )}
                </p>
              </div>
              <ButtonGroup>
                <Button
                  variant="primary"
                  fontSize="24px"
                  fontWeight="700"
                  onClick={() => {
                    window.open(
                      `${
                        process.env.REACT_APP_PAYMENT_V2_URL
                      }pay/?campaignCode=ExpirePage&userId=${userID}&bundleId=${
                        expiredBundle()?.latestBundleId?.bundleId
                      }`,
                      '_self'
                    );
                  }}
                >
                  {t('expired:buttonPrimary', 'Renew Now')}
                </Button>
                <Button
                  variant="secondary"
                  fontSize="24px"
                  fontWeight="700"
                  onClick={() => setShowPlans(true)}
                >
                  {t('expired:buttonSecondary', 'Other Plans')}
                </Button>
              </ButtonGroup>
            </>
          )}
        </Content>

        <img src={MikoExpiredImg} className="miko" alt="expired" />
      </Container>
    </div>
  );
};

export default Expired;
