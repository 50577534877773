import React, { useState } from 'react';
import moment from 'moment';
// import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import { isEmpty } from 'ramda';

import { SUBS_DISPLAY_STATUS, SUBS_TYPE } from '../../constants';

import Avatar from 'assets/img/avatar.png';

import {
  TrialSelector,
  ModalContentXButton,
  PopupModal,
} from 'components/Shared';

import { AutoRenewCancelModal, SubscriptionModal } from '..';
import TrialContent from './TrialContent';
import PaidContent from './PaidContent';

import {
  Container,
  ChildDetails,
  SubscriptionDetails,
  EarlyRenewModal,
} from './SubscriptionV2.styles';

const SubscriptionV2 = ({ childInfo }) => {
  const { childSubs } = useSelector((state) => state.parentSettings);
  const { data } = childSubs;

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [showEarlyRenewModal, setShowEarlyRenewModal] = useState(false);
  const [showTrialSelectorModal, setShowTrialSelectorModal] = useState(false);
  const [selectedSub, setSelectedSub] = useState(null);

  const isUpgradeEligible = () => {
    const isSingle = data.find(
      (item) => !item.doesUserHasMoreThanOneSingleSubjectSubscription
    );
    const isMultipleTrialAndExpired = data.every(
      (item) =>
        SUBS_TYPE[item.subscriptionTypeId] === 'trial' &&
        item.doesUserHasMoreThanOneSingleSubjectSubscription &&
        !item.isAutoRenewalOn
    );

    if (isSingle) {
      if (SUBS_TYPE[isSingle.subscriptionTypeId] === 'trial') {
        return (
          !isSingle.isAutoRenewalOn &&
          SUBS_DISPLAY_STATUS[isSingle.subscriptionStatus] === 'expired'
        );
      }
      return true;
    }
    if (isEmpty(data) || isMultipleTrialAndExpired) {
      return true;
    }
    return false;
  };

  return (
    <Container>
      <ModalContentXButton
        showModal={showCancelModal}
        closeModal={() => setShowCancelModal(false)}
        containerMinWidth="440px"
      >
        <AutoRenewCancelModal
          expiredDate={moment(selectedSub?.endDate).format('DD MMM YYYY')}
          onCancel={() => setShowCancelModal(false)}
          onProceed={() => {
            setShowCancelModal(false);
            setShowReasonModal(true);
          }}
        />
      </ModalContentXButton>
      <PopupModal show={showReasonModal} hide={() => setShowReasonModal(false)}>
        <SubscriptionModal
          heading="Are you sure you want to turn off auto-renew?"
          subHeading="Could you share with us your reason for turning off auto-renew？"
          isTrial={false}
          showDropDown
          paymentSubsId={selectedSub?.paymentSubscriptionId}
          closeModal={() => setShowReasonModal(false)}
          renewalDate={moment(selectedSub?.endDate).format('DD MMM YYYY')}
          childId={childInfo?.studentUserId}
        />
      </PopupModal>
      <PopupModal
        show={showEarlyRenewModal}
        hide={() => setShowEarlyRenewModal(false)}
      >
        <EarlyRenewModal>
          <p>
            You still have more than 12 months remaining on your current plan.
            Please come back later to renew. <br />
            If you need further assistance, please contact our support team at{' '}
            <a
              href="https://www.koobits.com/contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact Us page
            </a>
          </p>
          <button type="button" onClick={() => setShowEarlyRenewModal(false)}>
            Got it
          </button>
        </EarlyRenewModal>
      </PopupModal>

      <PopupModal
        show={showTrialSelectorModal}
        hide={() => setShowTrialSelectorModal(false)}
        centered
        backdrop="static"
        size="xl"
      >
        <TrialSelector
          userId={childInfo?.studentUserId}
          onClose={() => setShowTrialSelectorModal(false)}
          campaignCode="ParentPortal"
        />
      </PopupModal>

      <ChildDetails>
        <img
          src={childInfo?.avatarUrl ?? Avatar}
          alt="avatar"
          // eslint-disable-next-line no-param-reassign, no-return-assign
          onError={(e) => (e.target.src = Avatar)}
        />
        <div className="details">
          <p className="full-name">{childInfo?.fullName}</p>
          <p className="login-id">Login ID: {childInfo?.loginName}</p>
        </div>
      </ChildDetails>
      {!isEmpty(data) &&
        data.map((sub) => (
          <SubscriptionDetails key={sub.subscriptionId}>
            {SUBS_TYPE[sub.subscriptionTypeId] === 'trial' ? (
              <TrialContent
                sub={sub}
                setSelectedSub={setSelectedSub}
                setShowCancelModal={setShowCancelModal}
                userId={childInfo?.studentUserId}
              />
            ) : (
              <PaidContent
                sub={sub}
                setSelectedSub={setSelectedSub}
                setShowCancelModal={setShowCancelModal}
                setShowEarlyRenewModal={setShowEarlyRenewModal}
                setShowTrialSelectorModal={setShowTrialSelectorModal}
                userId={childInfo?.studentUserId}
              />
            )}
          </SubscriptionDetails>
        ))}

      {isUpgradeEligible() && (
        <TrialSelector
          userId={childInfo?.studentUserId}
          style={{ width: '100%' }}
          campaignCode="ParentPortal"
          isPopup={false}
        />
      )}
    </Container>
  );
};

export default SubscriptionV2;
