const trialBannerConfig = [
  {
    subjectId: 1,
    isOtpTrial: false,
    btnText: null,
    videoUrl: 'CwL9dcOLOsI',
    videoBtn: {
      outer: 'video-btn-primary',
      inner: 'light',
    },
  },

  {
    subjectId: 2,
    isOtpTrial: false,
    btnText: null,
    videoUrl: '2hxOzBuy9ag',
    videoBtn: {
      outer: 'video-btn-primary',
      inner: 'light',
    },
  },
];

export const hkPremiumTrialBanner = {
  subjectId: 1,
  isOtpTrial: false,
  btnText: null,
  videoUrl: 'upQJ5w0ss9I',
  videoBtn: {
    outer: 'video-btn-primary',
    inner: 'light',
  },
};

export default trialBannerConfig;
