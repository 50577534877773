import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPixel from 'react-facebook-pixel';
import { isEmpty, isNil } from 'ramda';
import moment from 'moment-timezone';
import { useMediaQuery } from 'react-responsive';
import ParentPortal from 'pages/ParentPortal';
import { getProducts, getCountryEligibleSubjects } from 'store/plan/planSlice';
import { setIsRead } from 'store/notification/notificationSlice';
import { resetActivity } from 'store/kooClass/kooClassSlice';
import useGetUserNotification from 'hooks/useGetUserNotification';
import trialBannerConfig, { hkPremiumTrialBanner } from 'constants/trialBanner';
import { PRODUCT_TYPE } from 'constants/products';
import Header from 'components/Header';
import BannerNotification from 'components/Notifications/BannerNotification';
import { Loader, ErrorModal } from 'components/Shared';
import { CCTrialBanner, TrialBanner } from './components';
import DashboardV1 from './DashboardV1';
import DashboardV2 from './DashboardV2';
import { Page } from './Dashboard.styles';
import { getEvents } from 'store/event/eventsListSlice';

const checkIfB2BPlanOnly = (subscriptions) => {
  const schoolPlanIndex = subscriptions.findIndex(
    (plan) => plan.PlanId === 8 || plan.PlanId === 9 || plan.PlanId === 10
  );
  if (subscriptions.length === 1 && schoolPlanIndex !== -1) {
    return true;
  }
  return false;
};

const SubjectBanners = ({
  currentProduct,
  userId,
  countryCode = '',
  countryZone,
}) => {
  const isOtpTrial = currentProduct.subscriptionDetails.IsOTPTrial;
  const calculateCurrentDay = () => {
    const start = moment
      .tz(currentProduct.startDate, countryZone)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const today = moment
      .tz(countryZone)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const daysLeft = today.diff(start, 'days');

    if (daysLeft >= 7) return 7;
    return daysLeft + 1;
  };
  if (isOtpTrial) {
    return <TrialBanner userId={userId} currentDay={calculateCurrentDay()} />;
  }
  const isHKMathPremiumTrial =
    currentProduct.subjectId === 1 &&
    currentProduct.productType.trim().toLowerCase() === 'premium' &&
    countryCode.trim().toLocaleLowerCase() === 'hkg';
  const currentTrial = isHKMathPremiumTrial
    ? hkPremiumTrialBanner
    : trialBannerConfig.find(
        (item) => item.subjectId === currentProduct.subjectId
      );
  if (currentTrial) {
    return (
      <CCTrialBanner
        currentDay={calculateCurrentDay()}
        currentTrial={currentTrial}
        userId={userId}
      />
    );
  }
  return null;
};

const Dashboard = () => {
  const dispatch = useDispatch();

  // Redux States
  const {
    plan,
    subject,
    isLoading: isProductsLoading,
    currentProduct,
    products,
    countryEligibility,
    getProductsError,
  } = useSelector((state) => state.plan);
  const { studentDetails, isLoading, hasSeenLoader, countryCode } = useSelector(
    (state) => state.studentDetails
  );
  const { isParentAccount } = useSelector((state) => state.login);

  const { activeType, activeId, activityName, activities } = useSelector(
    (state) => state.kooClass
  );
  const { isLoadingUserNotification, pageNotif, bannerNotif } = useSelector(
    (state) => state.notification
  );
  const { OTPEligibility } = useSelector((state) => state.trialSignup);

  const errorMessage = getProductsError || countryEligibility.error;

  const allLoading =
    isLoading ||
    isLoadingUserNotification ||
    isProductsLoading ||
    countryEligibility.isLoading ||
    OTPEligibility.isLoading;
  const isTeacherStudent = studentDetails && studentDetails.Roles.includes(12);
  const isTablet = useMediaQuery({ minWidth: 768 });
  // Component States
  const [showLoader, setShowLoader] = useState(!hasSeenLoader);
  // <<NOTIFICATIONS>>
  // calling notification endpoints
  useGetUserNotification();
  useEffect(() => {
    if (studentDetails !== null) {
      // For tracking page view of B2B plan only students
      const isB2BPlanOnly = checkIfB2BPlanOnly(studentDetails.Subscriptions);
      if (
        isB2BPlanOnly &&
        process.env.NODE_ENV === 'production' &&
        process.env.REACT_APP_NETLIFY !== 'true'
      ) {
        const options = {
          autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
          debug: process.env.NODE_ENV === 'development', // enable logs
        };
        ReactPixel.init(process.env.REACT_APP_PIXEL_ID, {}, options);
        ReactPixel.pageView(); // For tracking page view
      }
    }
  }, [dispatch, studentDetails]);
  useEffect(() => {
    if (
      !isNil(activeType) ||
      !isNil(activeId) ||
      !isNil(activityName) ||
      !isEmpty(activities)
    ) {
      dispatch(resetActivity());
    }
  }, [activeId, activeType, activities, activityName, dispatch]);

  // check page notif type isRead flag for redirect to notification page
  useEffect(() => {
    if (!isNil(pageNotif)) {
      const readStatus = pageNotif.isRead;
      dispatch(setIsRead(readStatus));
    }
  }, [dispatch, pageNotif]);

  // get brain master event availability
  useEffect(() => {
    dispatch(getEvents());
  }, [dispatch]);

  const reloadAction = () => {
    if (getProductsError) {
      dispatch(getProducts());
    }
    if (countryEligibility.error) {
      dispatch(getCountryEligibleSubjects(studentDetails?.CountryId));
    }
  };
  const renderNotification = () => {
    if (
      !isNil(bannerNotif) &&
      !showLoader &&
      (plan !== PRODUCT_TYPE.SCHOOL || isTablet)
    ) {
      return <BannerNotification page="dashboard" />;
    }
    return null;
  };

  const renderSubjectBanners = () => {
    if (
      !allLoading &&
      currentProduct &&
      currentProduct.isTrial &&
      (plan !== PRODUCT_TYPE.SCHOOL || isTablet)
    ) {
      return (
        <SubjectBanners
          currentProduct={currentProduct}
          userId={studentDetails?.UserId}
          countryCode={countryCode}
          product={products.find(
            (item) => item.subject.toLowerCase().trim() === subject
          )}
          countryZone={studentDetails?.ZoneName}
        />
      );
    }
  };
  const renderResult = () => {
    if (isParentAccount) {
      return <ParentPortal />;
    }
    const showDashboardv1 = PRODUCT_TYPE.SCHOOL === plan;
    return (
      <Page>
        <Header />
        {renderNotification()}
        {showDashboardv1 ? (
          <DashboardV1 isTeacherStudent={isTeacherStudent} />
        ) : (
          <DashboardV2 />
        )}

        {renderSubjectBanners()}
      </Page>
    );
  };
  return (
    <>
      {!hasSeenLoader && (
        <Loader
          isAPILoading={allLoading}
          showLoader={showLoader}
          setShowLoader={setShowLoader}
        />
      )}
      {renderResult()}
      {!isNil(errorMessage) && (
        <ErrorModal errorMessage={errorMessage} reloadAction={reloadAction} />
      )}
    </>
  );
};

export default Dashboard;
