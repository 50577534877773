import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TrialSelector, Button, ButtonPlay } from 'components/Shared';
import MikoSci from 'assets/img/science/miko-img.svg';
import { Container, CountDown, StyledPopupModal } from './TrialBanner.styles';

const TrialBanner = ({ currentDay, userId }) => {
  const { t } = useTranslation(['trial']);
  const [showModal, setShowModal] = useState(false);
  const { subject } = useSelector((state) => state.plan);
  const leftDays = 7 - currentDay;

  const closeModal = () => setShowModal(false);
  const handleStart = () => {
    window.open(`https://www.koobits.com/${subject}/start`);
  };
  return (
    <>
      <StyledPopupModal
        show={showModal}
        onHide={closeModal}
        centered
        backdrop="static"
        size="xl"
      >
        <TrialSelector
          onClose={closeModal}
          userId={userId}
          campaignCode="BottomBanner"
        />
      </StyledPopupModal>
      <Container data-cy="trial-banner">
        <CountDown>
          <div className={`count-down ${currentDay >= 7 && 'red'}`}>
            {t('trial:day', { number: currentDay }, `Day ${currentDay}`)}
          </div>
          <p>
            {t('trial:freeTrial', 'of free trial')} ({' '}
            <b>
              {t(
                'trial:remaining',
                { number: leftDays },
                `${leftDays} days left`
              )}
            </b>{' '}
            )
          </p>
          <Button
            variant="primary"
            fontWeight="bold"
            margin="0 10px 0 1rem"
            fontSize="20px"
            onClick={() => setShowModal(true)}
          >
            {t('trial:upgrade', 'Upgrade your plan')}
          </Button>
          <p>{t('trial:unlock', 'to unlock the full version')}</p>
        </CountDown>
        <div className="start-wrap">
          <img src={MikoSci} className="mascot" alt="miko" />
          <div className="start" aria-hidden="true" onClick={handleStart}>
            <p>{t('trial:getStart', 'Let‘s get started')}</p>
            <ButtonPlay size="33px" variant="primary" />
          </div>
        </div>
      </Container>
    </>
  );
};

export default TrialBanner;
