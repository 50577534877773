import styled from 'styled-components';
import Box from 'assets/img/trial/box-trial-banner.svg';
import { PopupModal } from 'components/Shared';

export const StyledPopupModal = styled(PopupModal)`
  .modal-body {
    width: 100%;
  }
`;
export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 30;
  padding: 0.5rem 0.8rem 0;
  &::before {
    height: 68px;
    position: absolute;
    content: '';
    background-image: linear-gradient(to right, #ffd903, #ffeb00, #ffc541);
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  p {
    margin: 0;
  }
  .start-wrap {
    display: flex;
    align-items: flex-end;
    position: absolute;
    bottom: 0;
    right: 0.8rem;
  }
  .start {
    display: flex;
    align-items: center;
    border-radius: 40px;
    border: 1px solid #f7a451;
    background-color: #ffcf03;
    padding: 4px 4px 4px 16px;
    margin-bottom: 12px;
    cursor: pointer;
    p {
      margin-right: 9px;
      line-height: 1;
      margin-top: 4px;
    }
  }
  .mascot {
    margin-right: 7px;
    @media (max-width: 1240px) {
      display: none;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    display: none;
  }
`;

export const CountDown = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 3rem;
  .red {
    color: #ff0000;
  }
  .count-down {
    font-size: 30px;
    font-weight: bold;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1.2rem;
    &::after {
      content: '';
      position: absolute;
      background: url(${Box}) no-repeat;
      width: 94px;
      height: 62px;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
  }
  span {
    font-weight: 600;
  }
`;
