import styled from 'styled-components';
import { Tooltip } from 'react-bootstrap';

export const StyledOrderSummary = styled.div`
  font-family: 'Muli';
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  p {
    margin-bottom: 0;
  }
  .title {
    text-align: center;
    color: #b5b5b5;
    font-size: 14px;
    margin-bottom: 16px;
  }
  .total {
    font-size: 14px;
    font-weight: 700;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    line-height: 1;
    margin-top: 4px;
    gap: 4px;
    padding-right: 20px;
    .price {
      font-size: 24px;
    }
  }
  .start-date {
    font-size: 11px;
    text-align: right;
    span {
      font-weight: 900;
    }
  }
  .orange {
    color: #ff5c02;
    font-weight: 900;
  }
  .disc-amount {
    width: max-content;
    right: 36px;
  }
  .highest {
    color: #ff5c02;
    text-align: right;
    margin-bottom: 0;
    padding: 0 20px 0 12px;
    font-size: 11px;
    position: relative;
    bottom: 14px;
  }
  .field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 11px;
    padding: 0 20px 0 12px;
    p {
      margin-bottom: 0;
    }
    .remaining {
      max-width: 130px;
    }
    &.coupon {
      background: #ffeedb;
      border-radius: 3px;
      padding: 4px 20px 4px 12px;
    }
    .discount {
      position: relative;
      font-weight: 900;
      font-size: 12px;
      .info {
        position: absolute;
        right: -22px;
        cursor: pointer;
      }
      &:before {
        content: '';
        position: absolute;
        left: -14px;
        height: 1px;
        width: 8px;
        background: black;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .price {
      font-size: 12px;
      font-weight: 900;
    }
    .tax {
      font-size: 12px;
    }
    &.top {
      align-items: flex-start;
      .prices {
        display: flex;
        gap: 0.8rem;
      }
      p {
        max-width: 120px;
      }
      .delete {
        color: #606060;
        font-size: 12px;
        text-decoration: line-through;
      }
      .original {
        font-size: 12px;
        text-align: right;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        span {
          display: block;
          font-weight: 900;
        }
      }
    }
  }
  .bottom {
    background: #fffef7;
    /* border-top: 1px solid #dbdbdb; */
    padding: 12px 0;
  }
`;

export const StyledOriginalPrice = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Muli';
  .base-prices {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    border-bottom: 1px solid #e2e2e2;
    padding: 0 5px 8px 5px;
    .price-item {
      display: flex;
      justify-content: space-between;
      font-size: 11px;
      .price-subject {
        color: #000000;
      }
      .price-amount {
        color: #b5b5b5;
      }
    }
  }
`;

export const StyledDiscountPrice = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Muli';
  font-size: 11px;
  font-weight: 700;
  .discount-prices {
    background: #fff9ed;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    border-bottom: 1px solid #e2e2e2;
    padding: 5px;

    .discount-breakdown {
      display: flex;
      justify-content: space-between;
      .orange {
        color: #ff5002;
        font-weight: 900;
      }

      .discount-amount {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        span {
          font-weight: 500;
        }
      }

      .existing-amount {
        display: flex;
        gap: 5px;
        align-items: center;
        .info {
          width: 13px;
          height: 13px;
          cursor: pointer;
        }
      }
    }
  }
`;

export const StyledTax = styled.div`
  color: #000000;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e2e2e2;
  padding: 0 5px 5px;
  font-size: 11px;
`;

export const SubTotal = styled.p`
  color: #020202;
  font-weight: 900;
  align-self: flex-end;
  font-size: 11px;
  padding: 5px 5px 0;
`;

export const StyledGrandTotal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  padding-top: 8px;
  .total-price {
    color: #000000;
    font-size: 14px;
    font-weight: 700;
    line-height: 8px;
    .orange {
      color: #ff5002;
    }
    .amount {
      font-size: 22px;
      font-weight: 900;
    }
  }
  .start-date {
    font-size: 11px;
    text-align: right;
    span {
      font-weight: 900;
    }
  }
`;

export const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    border-radius: 8px;
    max-width: max-content;
    padding: 1rem 12px 1rem 20px;
    background: white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }
  .detail {
    color: black;
    text-decoration: underline;
  }
  .desc {
    color: black;
    font-size: 10px;
    text-align: left;
    font-family: 'Muli';
    p {
      margin-bottom: 0;
    }
    .bold {
      font-weight: 900;
    }
    margin-bottom: 12px;
  }
  a {
    font-family: 'Muli';
  }
  && .arrow::before {
    border-top-color: white;
  }
`;
