import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalVideo from 'react-modal-video';
import { GAEvent } from 'helpers/googleAnalytics';
import MikoMath from 'assets/img/miko-trial-banner.svg';
import MikoSci from 'assets/img/science/miko-img.svg';
import { subjectIDs } from 'constants/products';
import { ButtonPlay } from 'components/Shared';
import { Container, CountDown, VideoBtn } from './CCTrialBanner.styles';

const CCTrialBanner = ({ currentDay, currentTrial, userId }) => {
  const { t } = useTranslation(['trialCCBanner']);
  const [showVideo, setShowVideo] = useState(false);
  const handleOpenVideo = () => {
    setShowVideo(true);
    GAEvent({
      category:
        process.env.REACT_APP_DEV === 'true'
          ? '[Dev] Student Portal'
          : 'Student Portal',
      action: 'Click Bottom Banner Tutorial Video',
      label: `${userId}-${currentTrial.subjectId}-Click Tutorial Video`,
    });
  };

  return (
    <Container data-cy="trial-banner">
      <ModalVideo
        channel="youtube"
        isOpen={showVideo}
        youtube={{
          autoplay: 1,
          mute: 0,
          rel: 0,
        }}
        videoId={currentTrial.videoUrl}
        onClose={() => setShowVideo(false)}
      />
      <CountDown isOtp={currentTrial.isOtpTrial}>
        <div className={`count-down ${currentDay >= 7 && 'red'}`}>
          {t('trialCCBanner:day', { number: currentDay }, `Day ${currentDay}`)}
        </div>
        <p>{t('trialCCBanner:freeTrial', 'of free trial')}</p>
      </CountDown>
      <VideoBtn>
        <div
          className={currentTrial.videoBtn.outer}
          onClick={handleOpenVideo}
          aria-hidden="true"
        >
          <ButtonPlay
            size="33px"
            variant={currentTrial.videoBtn.inner}
            styles={{ marginRight: '0.5rem' }}
          />
          <p>{t('trialCCBanner:getStart', "Let's learn how to get started")}</p>
        </div>
      </VideoBtn>
      {subjectIDs[currentTrial.subjectId] === 'Science' ? (
        <img src={MikoSci} className="mascot mascot-science" alt="miko" />
      ) : (
        <img src={MikoMath} className="mascot" alt="miko" />
      )}
    </Container>
  );
};

export default CCTrialBanner;
