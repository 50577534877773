import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isNil } from 'ramda';
import ReactPlayer from 'react-player/youtube';

import { setCurrentProduct } from 'store/plan/planSlice';

import { subjectNames, subjectIDs } from 'constants/products';
import { trialExpiredConfig } from './constants';

import Header from 'components/Header';
import { BackButton, Button, TrialSelector } from 'components/Shared';

import { ReactComponent as Premium } from 'assets/img/plan-toggle/icon-premium.svg';
import { ReactComponent as IconLock } from 'assets/img/expired/locked-ui.svg';

import { Page } from '../OTPTrial/OTPTrial.styles';
import { StyledButtonContainer, StyledTrialPopupModal } from '../index.styles';
import { StyledContainer, StyledLocked } from './OTPTrialExpired.styles';

const OTPTrialExpired = () => {
  const { t } = useTranslation([
    'scienceTrial',
    'mathTrial',
    'englishTrial',
    'bundleTrial',
    'common',
  ]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { subjectName } = useParams();
  const lowerSubjectName = subjectName.toLowerCase();
  const { studentDetails, isLoading } = useSelector(
    (state) => state.studentDetails
  );
  const {
    prevProduct,
    products,
    isLoading: productIsLoading,
  } = useSelector((state) => state.plan);
  const allLoading = productIsLoading || isLoading;

  const [showModal, setShowModal] = useState(false);

  const backHandler = () => {
    if (!isNil(prevProduct)) {
      dispatch(setCurrentProduct(prevProduct));
    }
    history.push(`/dashboard`);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    !allLoading &&
    products && (
      <>
        <div>
          <StyledTrialPopupModal
            show={showModal}
            onHide={closeModal}
            centered
            backdrop="static"
            size="xl"
          >
            <TrialSelector
              onClose={closeModal}
              userId={studentDetails?.UserId}
              campaignCode="ExpirePage"
            />
          </StyledTrialPopupModal>
        </div>
        <Page subject={lowerSubjectName}>
          <Header toggleMenu={false} toggleAccessMenu={false} />
          <StyledButtonContainer>
            <BackButton
              btnBgColor={trialExpiredConfig[lowerSubjectName].backBtnColor}
              padding="0.4rem 1rem"
              backText={t('common:back', 'Back')}
              onClick={backHandler}
            />
          </StyledButtonContainer>
          <StyledContainer subject={lowerSubjectName}>
            <div className="desc">
              <h3 className="main-title">
                {t(
                  `${trialExpiredConfig[lowerSubjectName].translationKey}:expired.title`,
                  `${trialExpiredConfig[lowerSubjectName].title}`
                )}
              </h3>
              <h5 className="main-desc">
                <p>
                  {t(
                    `bundleTrial:expired.desc`,
                    `Subscribe now to continue all the fun challenges & missions, and claim all your KoKo Credits!`
                  )}
                </p>
                <p>
                  {t(
                    `bundleTrial:expired.secondDesc`,
                    `Get our best offer via the link below 👇`
                  )}
                </p>
              </h5>

              <Button
                dataCy="try-button"
                variant="primary"
                fontWeight="700"
                fontSize="1.5rem"
                padding="0.7rem 3.5rem"
                onClick={() => setShowModal(true)}
              >
                {t('bundleTrial:buy', 'Buy Now')}
              </Button>
            </div>
            {trialExpiredConfig[lowerSubjectName].videoUrl ? (
              <ReactPlayer
                url={trialExpiredConfig[lowerSubjectName].videoUrl}
                loop={false}
                config={{
                  youtube: {
                    playerVars: {
                      rel: 0,
                      autoplay: 1,
                    },
                  },
                }}
                controls
              />
            ) : (
              <StyledLocked
                boxShadow={trialExpiredConfig[lowerSubjectName].boxShadow}
                bg={trialExpiredConfig[lowerSubjectName].img}
              >
                <div>
                  {lowerSubjectName === 'math' && (
                    <Premium width="76px" height="23px" />
                  )}
                  <p className="title">
                    KooBits{' '}
                    {t(
                      `common:subjects.${lowerSubjectName}`,
                      subjectIDs[subjectNames[lowerSubjectName]]
                    )}
                  </p>
                  <p className="second-desc">
                    {t(
                      `bundleTrial:expired.access`,
                      'Your access has expired.'
                    )}
                  </p>
                </div>
                <IconLock className="lock" />
              </StyledLocked>
            )}
          </StyledContainer>
        </Page>
      </>
    )
  );
};

export default OTPTrialExpired;
