/* eslint-disable no-nested-ternary */
import React from 'react';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';

import {
  SUBS_DISPLAY_STATUS,
  PAYMENT_PROVIDER,
  BUNDLE_TYPE_SUBJECT_COUNT,
} from '../../constants';

import { ReactComponent as CC } from './imgs/icon-credit-card.svg';

import { Button } from 'components/Shared';

import {
  SubsBottom,
  SubsTop,
  SubjectPlanLabel,
  StatusLabel,
} from './SubscriptionV2.styles';

const TrialContent = ({ sub, setSelectedSub, setShowCancelModal, userId }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 610px)` });

  const clickHandler = () => {
    window.location.href = `${process.env.REACT_APP_PAYMENT_V2_URL}pay/?campaignCode=ParentPortal&userId=${userId}&bundleId=${sub.bundleId}`;
  };

  const buttonDisplay = (isAutoRenew, subsStatus) => {
    if (isAutoRenew && subsStatus === 'expired') {
      return (
        <Button
          borderRadius="2px"
          variant="orange3"
          width={isMobile ? '100%' : '142px'}
          fontSize="16px"
          onClick={clickHandler}
        >
          Renew Now
        </Button>
      );
    }
    if (!isAutoRenew && subsStatus === 'active') {
      return (
        <Button
          borderRadius="2px"
          variant="orange3"
          width={isMobile ? '100%' : '142px'}
          fontSize="16px"
          onClick={clickHandler}
        >
          Buy Now
        </Button>
      );
    }
  };

  return (
    <>
      <SubsTop
        active={SUBS_DISPLAY_STATUS[sub.subscriptionStatus] !== 'expired'}
      >
        <div className="bundle-info">
          <p className="bundle-name">
            {BUNDLE_TYPE_SUBJECT_COUNT[sub.bundleTypeId] !== '1' && (
              <SubjectPlanLabel>
                {BUNDLE_TYPE_SUBJECT_COUNT[sub.bundleTypeId]} subjects Plan
              </SubjectPlanLabel>
            )}
            {sub.bundleName}{' '}
            <StatusLabel
              active={SUBS_DISPLAY_STATUS[sub.subscriptionStatus] !== 'expired'}
            >
              {SUBS_DISPLAY_STATUS[sub.subscriptionStatus] !== 'expired'
                ? 'Active'
                : 'Expired'}
            </StatusLabel>
          </p>
          {SUBS_DISPLAY_STATUS[sub.subscriptionStatus] === 'expired' ? (
            <p className="bundle-period">
              Annual plan, Valid period{' '}
              {moment(sub.startDate).format('DD MMM YYYY')} -
              {moment(sub.endDate).format('DD MMM YYYY')}
            </p>
          ) : (
            <p className="bundle-period">
              7-Day Free Trial, Expires on{' '}
              {moment(sub.endDate).format('DD MMM YYYY')}
            </p>
          )}
        </div>
        {buttonDisplay(
          sub.isAutoRenewalOn,
          SUBS_DISPLAY_STATUS[sub.subscriptionStatus]
        )}
      </SubsTop>
      <SubsBottom
        active={SUBS_DISPLAY_STATUS[sub.subscriptionStatus] !== 'expired'}
      >
        <div className="bundle-price">
          {SUBS_DISPLAY_STATUS[sub.subscriptionStatus] === 'expired' ? (
            <p className="renewal-rate">
              Expired on {moment(sub.endDate).format('DD MMM YYYY')}.
            </p>
          ) : sub.isAutoRenewalOn ? (
            <>
              <p className="renewal-rate">
                Annual Plan starts on{' '}
                {moment(sub.endDate).format('DD MMM YYYY')}.
              </p>
              <div className="payment-details">
                Payment Method:{' '}
                {PAYMENT_PROVIDER[sub.paymentProviderId] === 'stripe' && <CC />}{' '}
                **************{sub.paymentMethodText} (Upcoming payment on{' '}
                {moment(sub.endDate).format('DD MMM YYYY')}）
              </div>
            </>
          ) : (
            <div className="period-date">
              This account still can be used until{' '}
              {moment(sub.endDate).format('DD MMM YYYY')}
            </div>
          )}
        </div>
        {sub.isAutoRenewalOn ? (
          <button
            type="button"
            className="cancel-btn"
            onClick={() => {
              setSelectedSub(sub);
              setShowCancelModal(true);
            }}
          >
            {SUBS_DISPLAY_STATUS[sub.subscriptionStatus] === 'expired'
              ? 'Cancel Auto-Renewal'
              : 'Cancel Free-Trial'}
          </button>
        ) : (
          <p>Cancelled Free Trial</p>
        )}
      </SubsBottom>
    </>
  );
};

export default TrialContent;
