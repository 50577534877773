import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4.5rem;
  p {
    margin-bottom: 0;
  }
  @media (max-width: 1024px) {
    gap: 2rem;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .miko {
    max-width: 497px;
  }
`;

export const Content = styled.div`
  width: 380px;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  .title {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    font-family: 'Muli';
    .text {
      font-weight: 900;
      color: #000000;
      font-size: 26px;
    }
    .period {
      color: #ff0000;
      font-size: 11px;
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 1.2rem;
  margin-top: 0.5rem;
`;
